/* eslint-disable react/react-in-jsx-scope */
import { color } from '@smoothincorg/smooth-ui'
import { DefaultLoader } from 'src/ui/Loader'

// TODO Loading.tsxかこのファイルのどちらかを削除する
export default function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: color.white,
      }}
    >
      <DefaultLoader />
    </div>
  )
}
