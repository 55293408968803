import { color, Text } from '@smoothincorg/smooth-ui'
import SyncLoader from 'react-spinners/SyncLoader'

export const DefaultLoader = () => {
  return (
    <div
      className="loading"
      style={{
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 0',
        height: 100,
      }}
    >
      <SyncLoader color={color.main} />
      <Text size={14} weight="bold" color="main">
        読み込み中です…
      </Text>
    </div>
  )
}
