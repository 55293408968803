import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { LIFF_ID } from 'src/lib/constants'
import {
  getAccessTokenFromCode,
  getAccessTokenFromHash,
  setCookieToken,
  verifyAccessToken,
} from 'src/lib/line'
import Loading from 'src/ui/page-directory/Loading/LoadingComponent'

// TODO /simplified-examinationと/simplified-examination/editは簡易審査の古いpathで廃止予定なので、期間を置いたのちに削除する。
export const APP_DIRECTORY_PAGES = [
  '/liff/examination-form',
  '/simplified-examination',
  '/simplified-examination/edit',
]

const initializeLiffOnFirstUrl = async (
  query: Record<string, string | string[] | undefined>,
  hash: string,
  replace: (url: string) => Promise<boolean>
) => {
  const { default: liff } = await import('@line/liff')
  const liffState = query['liff.state']

  /* LIFFで開いたルート */
  if (liff.isInClient() && hash.includes('access_token')) {
    const accessToken = getAccessTokenFromHash(hash || '')
    const isVerified = await verifyAccessToken(accessToken)

    if (!isVerified) {
      throw new Error('Failed to verify access token')
    }

    await setCookieToken(accessToken)

    await liff.init({ liffId: LIFF_ID }).catch((err) => {
      console.error(err)
    })

    return
  }

  /* PCとかでliffじゃないurl（app.smooth.jp/liff/examination-form）とかに直接アクセスして戻ってきたルート */
  if (query['code'] && !liff.isInClient()) {
    const liffStore = localStorage.getItem('LIFF_STORE:' + LIFF_ID + ':loginTmp')
    const parsed = JSON.parse(liffStore || '{}')
    const codeVerifier = parsed['codeVerifier']

    if (codeVerifier) {
      const tokenRes = await getAccessTokenFromCode(
        query['code'] as string,
        query['liffRedirectUri'] as string,
        codeVerifier
      )

      await setCookieToken(tokenRes.accessToken)

      replace(liffState as string)
      return
    }
  }

  /* その他はログインに誘導 */
  liff.login({ redirectUri: (window.location.hostname + liffState) as string })
}

const initializeLiffAction = async (router: ReturnType<typeof useRouter>) => {
  if (!router.isReady) return

  const liffState = router.query['liff.state']

  if (!liffState) return

  const shouldProcessAsAppDirectory = APP_DIRECTORY_PAGES.some((page) => liffState.includes(page))

  if (shouldProcessAsAppDirectory) {
    const hash = `#${router.asPath.split('#')[1]}`
    initializeLiffOnFirstUrl(router.query, hash, router.replace)
    return
  }

  const { default: liff } = await import('@line/liff/core')

  liff.init({ liffId: LIFF_ID }).catch((err) => {
    console.error(err)
  })
}

const IndexPage: React.FC = () => {
  const router = useRouter()

  useEffect(() => {
    initializeLiffAction(router)
  }, [router])

  return (
    <div className="_home">
      <Loading />
    </div>
  )
}

export default IndexPage
