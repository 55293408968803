import { camelCase } from 'src/lib/aikagi'
import { AccessTokenRes } from 'src/pages/api/line/token'

export const getAccessTokenFromHash = (hash: string) => {
  const accessToken = hash.match(/access_token=([^&]+)/)?.[1]
  if (accessToken) return accessToken

  throw new Error('Failed to get access token')
}

export const getAccessTokenFromCode = async (
  code: string,
  redirect_uri: string,
  code_verifier: string
) => {
  const res = await fetch(
    '/api/line/token?' +
      new URLSearchParams({
        code,
        redirect_uri,
        code_verifier,
      }).toString(),
    {
      method: 'GET',
    }
  )

  const json: AccessTokenRes = await res.json()

  if (json.access_token) {
    return camelCase(json)
  }

  throw new Error('Failed to get access token')
}

export const setCookieToken = async (token: string) => {
  const res = await fetch('/api/line/login', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cache: 'no-store',
  })

  const json = await res.json()

  if (json.success) {
    return { success: true }
  }

  throw new Error('Failed to login')
}

export const verifyAccessToken = async (token: string) => {
  const res = await fetch('https://api.line.me/oauth2/v2.1/verify?access_token=' + token, {
    cache: 'no-store',
  })

  return res.status === 200
}
